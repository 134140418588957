<template>
	<div class="index_zong">
		<div class="i_left">
			<div class="top_list">
				<div class="item" v-for="(item, index) in list" :key="index">
					<div class="data">{{ item.number }}</div>
					<div class="name">{{ item.title }}</div>
				</div>
			</div>
			<div class="bottom_list">
				<div class="title">库存提醒!</div>
				<div class="list">
					<div class="item" v-for="(item, index) in goodsList" :key="index">
						<div class="img">
							<img class="" src="../../static/img/wxl_touxiang.jpg" alt="" />
						</div>
						<div class="them">
							<div class="top">
								<div class="text">商品ID：{{item.goodsid}}</div>
								<div class="stock">{{item.stock}}/{{item.gross}}</div>
							</div>
							<div class="bottom">
								<div class="tips">即将售罄</div>
								<div class="compile" @click="compile">编辑</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="i_right">
			<div class="box">
				<div class="b_left">
					<div class="i_img">
						<img class="img" src="../../static/img/wxl_touxiang.jpg" alt="">
					</div>
					<div class="i_title">信用等级</div>
					<div class="i_title">保障金</div>
				</div>
				<div class="b_rigth">
					<div class="b_name">xingming</div>
					<div class="name">
						<span class="xing iconfont icon-shoucang"></span>
						<span class="xing iconfont icon-shoucang"></span>
						<span class="xing iconfont icon-shoucang"></span>
						<span class="xing iconfont icon-shoucang"></span>
						<span class="xing iconfont icon-shoucang"></span>
					</div>
					<div class="name_btn">已缴纳</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				act: 0,
				list: [{
						title: "待付款",
						number: 0,
					},
					{
						title: "待发货",
						number: 0,
					},
					{
						title: "待售后",
						number: 0,
					},
					{
						title: "待评价",
						number: 0,
					},
					{
						title: "投诉",
						number: 0,
					},
				],
				goodsList: [{
						goodsid: "123213", //商品ID
						gross: 100, //总量
						stock: 20, //库存
					},
					{
						goodsid: "123213",
						gross: 100, //总量
						stock: 10, //库存
					},
					{
						goodsid: "123213",
						gross: 100, //总量
						stock: 10, //库存
					},
					{
						goodsid: "123213",
						gross: 100, //总量
						stock: 30, //库存
					},
				],
			};
		},
		methods: {
			changetab(index) {
				this.act = index
			},
			//编辑
			compile() {
				this.$router.push({
					path: "/goods/select_class"
				})
			}
		}
	};
</script>

<style scoped>
	@import "./goods_index.css";
</style>
